<template>
  <div class="app-container">
    <div class="top" style="margin: 10px 0">
      <el-button type="success" icon="el-icon-plus" @click="showAdd"
        >新增权限</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%; margin-bottom: 20px; text-align: center"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="title"
        label="标题"
        sortable
        width="180"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="标识"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="path"
        label="路径"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="pid"
        label="上级ID"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="icon"
        label="图标"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="component"
        label="组件"
        align="center"
      ></el-table-column>
      <el-table-column prop="hidden" label="隐藏" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.hidden == 0">显示</el-tag>
          <el-tag type="danger" v-else>隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="showEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="handelType === 'new' ? '新增权限' : '编辑权限'"
      :visible.sync="dialogVisible"
      width="800px"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="100px"
        :inline="true"
        class="inlineForm"
        :rules="rules"
      >
        <el-form-item label="名称" prop="title">
          <el-input v-model="form.title" class="inlineFormInput" />
        </el-form-item>
        <el-form-item label="标识" prop="name">
          <el-input v-model="form.name" class="inlineFormInput" />
        </el-form-item>
        <el-form-item label="父级节点">
          <el-select v-model="form.pid" class="inlineFormSelect">
            <el-option label="顶级分类" :value="0"></el-option>
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="图标">
          <el-select v-model="form.icon" class="value">
            <el-option
              v-for="(item, index) in iconList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="隐藏">
          <el-radio-group v-model="form.hidden" class="inlineFormInput">
            <el-radio :label="0" border>显示</el-radio>
            <el-radio :label="1" border>隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="排序" prop="sorts">
          <el-input v-model="form.sorts" class="inlineFormInput" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="handelType === 'new'"
          type="primary"
          @click="handleAdd('ruleForm')"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/permissionVue";

export default {
  data() {
    return {
      rules: {
        //规则
        title: [
          //名称
           { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        name: [
          //标识
          { required: true, message: "请输入标识", trigger: "blur" },
        ],
        sorts: [
          //排序
          { required: true, message: "请输入排序", trigger: "blur" },
        ],
      },
      listData: [],
      handelType: "",
      dialogVisible: false,
      form: {
        component: "",
        hidden: 0,
        icon: "",
        name: "",
        path: "",
        pid: 0,
        remark: "",
        sorts: 0,
        title: "",
      },
      typeList: [],
      iconList: [
        {
          label: "表格",
          value: "table",
        },
        {
          label: "用户",
          value: "user",
        },
        {
          label: "案例",
          value: "example",
        },
        {
          label: "导航",
          value: "guide",
        },
        {
          label: "国际",
          value: "international",
        },
        {
          label: "购物",
          value: "shopping",
        },
        {
          label: "星级",
          value: "star",
        },
        {
          label: "图表",
          value: "chart",
        },
        {
          label: "金钱",
          value: "money",
        },
      ],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.typeList = [];
      await api.getTreeList().then((res) => {
        this.listData = res.data;
      });
      this.listData.forEach((item) => {
        this.typeList.push({ name: item.name, id: item.id });
      });
      console.log(this.typeList);
    },
    showEdit(obj) {
      this.handelType = "edit";
      this.form = obj;
      this.dialogVisible = true;
    },
    handleEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.pid == 0) {
            this.form.component = "layout/Layout";
            this.form.path = "/" + this.form.name;
          } else {
            let routeName = this.typeList.find(
              (item) => this.form.pid == item.id
            ).name;
            this.form.component = "/" + routeName + "/" + this.form.name;
            this.form.path = this.form.name;
          }
          api.update(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "更新成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    showAdd() {
      this.handelType = "new";
      this.form = {
        component: "",
        hidden: 0,
        icon: "table",
        name: "",
        path: "",
        pid: 0,
        remark: "",
        sorts: 0,
        title: "",
      };
      this.dialogVisible = true;
    },
    handleAdd(formName) {
      //顶级路由的情况
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.pid == 0) {
            this.form.component = "layout/Layout";
            this.form.path = "/" + this.form.name;
          } else {
            let routeName = this.typeList.find(
              (item) => this.form.pid == item.id
            ).name;
            this.form.component = "/" + routeName + "/" + this.form.name;
            this.form.path = this.form.name;
          }

          api.create(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({ message: "新增成功", type: "success" });
            } else {
              this.$message({ message: res.message, type: "error" });
            }
            this.getDataList();
            this.dialogVisible = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDel(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = [];
        arr.push(id);

        api.remove({ ids: arr }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: res.message, type: "error" });
          }
          this.getDataList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inlineForm .inlineFormSelect {
  width: 260px;
}

.inlineForm .inlineFormInput {
  width: 260px;
}

.el-form--inline .el-form-item {
  width: 48%;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
</style>
